import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Image,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { MotionDiv } from "../../components";
import Title from "../../components/layout/Title";
import TextEditor from "../../components/layout/TextEditor";
import { useCreateContentMutation, useGetContentMutation, useUploadImageMutation } from "../../features/apiSlice";
import { getError } from "../../utils/error";
import { selectOptions, setDomain, setSubdomain, unsetOptions, unsetSubtopic, unsetTopic } from "../../features/optionSlice";
import { fetchDomains, fetchSubdomains } from "../../utils/apis";
import FormField from "../../components/layout/FormField";
import { useDispatch, useSelector } from "react-redux";


export default function WebsiteContent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {subdomain} = useParams();

  const [uploadImage,{isLoading:uploadLoading}] = useUploadImageMutation();
  const [getContent,{isLoading:getLoading}] = useGetContentMutation();
  const [createContent,{isLoading:createLoading}] = useCreateContentMutation();


  const [logo, setLogo] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [homeHeading, setHomeHeading] = useState("");
  const [homeTagline, setHomeTagline] = useState("");
  const [aboutDescription, setAboutDescription] = useState("");
  const [aboutImage, setAboutImage] = useState("");
  const [insightHeading, setInsightHeading] = useState("");
  const [insightSubheading, setInsightSubheading] = useState("");
  const [insightImage, setInsightImage] = useState([]); 

  const logoInputRef = useRef(null);
  const bannerInputRef = useRef(null);
  const aboutInputRef = useRef(null);
  const insightInputRef = useRef(null);



                useEffect(()=>{
                    if(subdomain){
                        fetchContent();
                    }
                },[subdomain])
    

                 const fetchContent = async () => {
                    try {
                      const data = await getContent(subdomain).unwrap();
                      console.log(data);

                      setLogo(data?.logo);
                      setAboutImage(data?.aboutUs?.aboutImage);
                      setAboutDescription(data?.aboutUs?.description);

                      setBannerImage(data?.homePage?.bannerImage);
                      setHomeHeading(data?.homePage?.heading);
                      setHomeTagline(data?.homePage?.tagline);
                      
                      setInsightHeading(data?.insightSection?.heading);
                      setInsightSubheading(data?.insightSection?.subheading);
                      setInsightImage(data?.insightSection?.insightImage);
                      
                      setSubdomain(data?.subdomain_reference);
                    //   setTitle(data?.page?.title);
                    //   setDescription(data?.page?.description);
                      // setPages(data?.pages);
                    } catch (error) {
                      getError(error);

                    }
                  };

  const handleUploadImage = async({image,setImage,})=>{
    try {
        const form = new FormData()
        form.append("image",image)

        const data = await uploadImage(form).unwrap();

        console.log(data);
        
        setImage && setImage(data?.location)

    } catch (error) {
      getError(error);  
    }
  }

  const submitHandler = async(e) => {
    e.preventDefault();

    try {
        const newContent = {
            logo: logo,
            homePageBannerImage: bannerImage,
            homePageHeading: homeHeading,
            homePageTagline: homeTagline,
        
            aboutUsDescription: aboutDescription,
            aboutUsImage:  aboutImage,
        
            insightHeading: insightHeading,
            insightSubheading: insightSubheading,
            insightImage: insightImage, 

            subdomain_reference:subdomain
            
          };
      
          const data = await createContent(newContent).unwrap();

          toast.success(data?.message);

          navigate(-1);
      
    } catch (error) {
        getError(error);
    }
   

  };

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    handleUploadImage({image:file,setImage:setLogo});
  };
  const handleLogoUploadButtonClick = (e) => {
    e.preventDefault();
    logoInputRef.current.click();
  };

  const handleLogoRemove = () => {
    setLogo(null); 
  };

  const handleBannerChange = (event) => {
    const file = event.target.files[0];
    handleUploadImage({image:file,setImage:setBannerImage});

  };

  const handleBannerUploadButtonClick = (e) => {
    e.preventDefault();
    bannerInputRef.current.click();
  };

  const handleBannerRemove = () => {
    setBannerImage(null); 
  };

  const handleAboutChange = (event) => {
    const file = event.target.files[0];
    handleUploadImage({image:file,setImage:setAboutImage});

  };
  const handleAboutUploadButtonClick = (e) => {
      e.preventDefault();
      aboutInputRef.current.click();
    };
    
    const handleAboutRemove = () => {
        setAboutImage(null); 
    };
    
    
    
    const handleInsightChange = (event) => {
      const file = event.target.files[0];
      handleUploadImage({image:file,setImage:setInsightImage});
  
    };
  const handleInsightUploadButtonClick = (e) => {
    e.preventDefault();
    insightInputRef.current.click();
  };
  const handleInsightRemove = () => {
   
    setInsightImage(null);
  };

  return (
    <MotionDiv>
      <Title>{"Website Content"}</Title>
      <Card className="mt-4">
        {getLoading ? (
          <Container className="p-4">
            <Skeleton height={30} count={10} />
          </Container>
        ) : (
          <Container className="p-4">



            <Form onSubmit={submitHandler}>
              {/* Logo Field */}
              <Row className="mb-4">
                <Col sm={12} md={3}>
                  <Form.Label>Logo Image</Form.Label>
                </Col>
                <Col sm={12} md={9}>
                  <input
                    ref={logoInputRef}
                    type="file"
                    onChange={handleLogoChange}
                    style={{ display: "none" }}
                    accept="image/*"
                  />
                  <Button
                    onClick={handleLogoUploadButtonClick}
                    style={{
                      color: "white",
                      backgroundColor: "var(--primary-color)",
                      borderRadius: "0.25rem",
                      padding: "0.5rem",
                      fontWeight: 500,
                      fontSize: "0.65rem",
                      marginRight: "20px",
                      border: "none",
                    }}
                    disabled={uploadLoading}
                  >
                    {uploadLoading?
                    <Spinner animation="grow" size="sm"/>
                    :
                    "Upload Logo"
}
                  </Button>
                  {logo && (
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <Image
                        src={logo}
                        alt="Logo Preview"
                        style={{
                          maxWidth: "100px",
                          maxHeight: "100px",
                          objectFit: "cover",
                          marginTop: "10px",
                        }}
                      />
                      <Button
                        variant="transparent"
                        className="p-0 delete-btn"
                        size="sm"
                        style={{
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                        }}
                        onClick={handleLogoRemove}
                      >
                        X
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>

              {/* Home Page Section */}
              <Row className="mb-4">
                <Col sm={12} md={3}>
                  <Form.Label>Banner Image</Form.Label>
                </Col>
                <Col sm={12} md={9}>
                  <input
                    ref={bannerInputRef}
                    type="file"
                    onChange={handleBannerChange}
                    style={{ display: "none" }}
                    accept="image/*"
                  />
                  <Button
                    onClick={handleBannerUploadButtonClick}
                    style={{
                      color: "white",
                      backgroundColor: "var(--primary-color)",
                      borderRadius: "0.25rem",
                      padding: "0.5rem",
                      fontWeight: 500,
                      fontSize: "0.65rem",
                      marginRight: "20px",

                      border: "none",
                    }}
                    disabled={uploadLoading}
                  >
                    {uploadLoading?
                    <Spinner animation="grow" size="sm"/>
                    :
                   "Upload Banner Image"
}
                  </Button>
                  {bannerImage && (
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <Image
                        src={bannerImage}
                        alt="Banner Preview"
                        style={{
                          maxWidth: "100px",
                          maxHeight: "100px",
                          objectFit: "cover",
                          marginTop: "10px",
                        }}
                      />
                      <Button
                        variant="transparent"
                        className="p-0 delete-btn"
                        size="sm"
                        style={{
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                        }}
                        onClick={handleBannerRemove}
                      >
                        X
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="mb-4">
                <Col sm={12} md={3}>
                  <Form.Label>Home Heading</Form.Label>
                </Col>
                <Col sm={12} md={9}>
                  <Form.Control
                    type="text"
                    placeholder="Enter Home Heading"
                    value={homeHeading}
                    onChange={(e) => setHomeHeading(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col sm={12} md={3}>
                  <Form.Label>Home Tagline</Form.Label>
                </Col>
                <Col sm={12} md={9}>
                  <Form.Control
                    type="text"
                    placeholder="Enter Home Tagline"
                    value={homeTagline}
                    onChange={(e) => setHomeTagline(e.target.value)}
                  />
                </Col>
              </Row>

              {/* About Us Section */}
              <Row className="mb-4">
                <Col sm={12} md={3}>
                  <Form.Label>About Us Description</Form.Label>
                </Col>
                <Col sm={12} md={9}>
                  <TextEditor
                    description={aboutDescription}
                    setDescription={setAboutDescription}
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col sm={12} md={3}>
                  <Form.Label>About Image</Form.Label>
                </Col>
                <Col sm={12} md={9}>
                  <input
                    ref={aboutInputRef}
                    type="file"
                    onChange={handleAboutChange}
                    style={{ display: "none" }}
                    accept="image/*"
                  />
                  <Button
                    onClick={handleAboutUploadButtonClick}
                    style={{
                      color: "white",
                      backgroundColor: "var(--primary-color)",
                      borderRadius: "0.25rem",
                      padding: "0.5rem",
                      fontWeight: 500,
                      fontSize: "0.65rem",
                      marginRight: "20px",
                      border: "none",
                    }}
                    disabled={uploadLoading}
                  >
                    {uploadLoading?
                    <Spinner animation="grow" size="sm"/>
                    :
                   " Upload About Image"
}
                  </Button>
                  {aboutImage && (
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <Image
                        src={aboutImage}
                        alt="About Preview"
                        style={{
                          maxWidth: "100px",
                          maxHeight: "100px",
                          objectFit: "cover",
                          marginTop: "10px",
                        }}
                      />
                      <Button
                        variant="transparent"
                        className="p-0 delete-btn"
                        size="sm"
                        style={{
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                        }}
                        onClick={handleAboutRemove}
                      >
                        X
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>

              {/* Insight Section */}
              <Row className="mb-4">
                <Col sm={12} md={3}>
                  <Form.Label>Insight Heading</Form.Label>
                </Col>
                <Col sm={12} md={9}>
                  <Form.Control
                    type="text"
                    placeholder="Enter Insight Heading"
                    value={insightHeading}
                    onChange={(e) => setInsightHeading(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col sm={12} md={3}>
                  <Form.Label>Insight Subheading</Form.Label>
                </Col>
                <Col sm={12} md={9}>
                  <Form.Control
                    type="text"
                    placeholder="Enter Insight Subheading"
                    value={insightSubheading}
                    onChange={(e) => setInsightSubheading(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col sm={12} md={3}>
                  <Form.Label>Insight Image</Form.Label>
                </Col>
                <Col sm={12} md={9}>
                  <input
                    ref={insightInputRef}
                    type="file"
                    onChange={handleInsightChange}
                    style={{ display: "none" }}
                    multiple
                    accept="image/*"
                  />
                  <Button
                    onClick={handleInsightUploadButtonClick}
                    style={{
                      color: "white",
                      backgroundColor: "var(--primary-color)",
                      borderRadius: "0.25rem",
                      padding: "0.5rem",
                      fontWeight: 500,
                      fontSize: "0.65rem",
                      border: "none",
                    }}
                    disabled={uploadLoading}
                  >
                    {uploadLoading?
                    <Spinner animation="grow"  size="sm"/>
                    :
                   "Upload Image"
}
                  </Button>
                  {insightImage && (
                    <div
                    style={{ position: "relative", display: "inline-block" }}
                  >
                     
                          <Image
                            src={insightImage}
                            alt={`Insight Preview`}
                            style={{
                              maxWidth: "100px",
                              maxHeight: "100px",
                              objectFit: "cover",
                            }}
                          />
                          <Button
                            variant="transparent"
                            className="p-0 delete-btn"
                            size="sm"
                            style={{
                              position: "absolute",
                              top: "0px",
                              right: "0px",
                            }}
                            onClick={handleInsightRemove}
                          >
                            X
                          </Button>
                    </div>
                  )}
                </Col>
              </Row>
              {/* Submit Button */}
              <Row className="my-3">
                <Col className="text-end" md={12}>
                  <Button
                    type="submit"
                    className="blue-btn px-4"
                    disabled={createLoading}
                  >
                    {createLoading ? (
                      <>
                        Loading...
                        <Spinner size="sm" />
                      </>
                    ) : (
                      "Save"
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        )}
      </Card>
    </MotionDiv>
  );
}