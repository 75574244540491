import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import FormField from "../../components/layout/FormField";
import SearchField from "../../components/layout/SearchField";
import CustomTable, {
  AddButton,
  DeleteButton,
  EditButton,
  ViewButton,
} from "../../components/layout/CustomTable";
import { MotionDiv } from "../../components";
import { FiTrash } from "react-icons/fi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CiSquarePlus } from "react-icons/ci";
import { FaRegQuestionCircle, FaRegSave, FaSave } from "react-icons/fa";
import UploadImages from "../../components/layout/UploadImages";
import ModalTemplate from "../../components/modals/ModalTemplate";
import { useNavigate, useParams } from "react-router-dom";
import References from "../../components/layout/References";
import { FaRegRectangleXmark } from "react-icons/fa6";
import {
  useCreateQuestionMutation,
  useGetDomainsMutation,
  useGetQuestionByIdMutation,
  useGetSubTopicsMutation,
  useGetSubdomainsMutation,
  useGetTopicsMutation,
  useUpdateQuestionByIdMutation,
} from "../../features/apiSlice";
import {
  fetchDomains,
  fetchSubdomains,
  fetchSubtopics,
  fetchTopics,
} from "../../utils/apis";
import { getError } from "../../utils/error";
import { toast } from "react-toastify";
import TextEditor from "../../components/layout/TextEditor";
import { AnswerIcon, QuestionIcon } from "./questionIcons";
import { useDispatch, useSelector } from "react-redux";
import {
  selectOptions,
  setDomain,
  setSubdomain,
  setSubtopic,
  setTopic,
  unsetOptions,
  unsetSubtopic,
  unsetTopic,
} from "../../features/optionSlice";

function AddEditQuestion() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [curPage, setCurPage] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(10);
  const { id } = useParams();
  const [getTopics, { isLoading: topicLoading }] = useGetTopicsMutation();
  const [getSubtopics, { isLoading: subtopicLoading }] =
    useGetSubTopicsMutation();
  const [getDomains, { data: domainData }] = useGetDomainsMutation();
  const [getSubdomains, { data: subdomainLoading }] =
    useGetSubdomainsMutation();
  const [createQuestion, { isLoading: createLoading }] =
    useCreateQuestionMutation();
  const [getQuestionById, { isLoading: getloading }] =
    useGetQuestionByIdMutation();
  const [updateQuestionById, { isLoading: updateloading }] =
    useUpdateQuestionByIdMutation();

  const [topics, setTopics] = useState([]);
  const [subtopics, setSubtopics] = useState([]);
  const [domains, setDomains] = useState([]);
  const [subdomains, setSubdomains] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [queImages, setQueImages] = useState([]);
  const [exImages, setExImages] = useState([]);
  const [preExImages, setPreExImages] = useState([]);
  const [preQueImages, setPreQueImages] = useState([]);
  const [references, setReferences] = useState([]);
  const { domain, topic, subtopic, subdomain } = useSelector(selectOptions);

  const [form, setForm] = useState({
    questionType: "Select Best Option",
    difficultyLevel: "Basic",
  });

  useEffect(() => {
    if (id) {
      fetchQuestionById();
    }
  }, [id]);

  const fetchQuestionById = async () => {
    try {
      const data = await getQuestionById(id).unwrap();
      console.log(data);
      const que = data?.question;
      console.log("question:", que.question);
      setForm({
        question: que?.question,
        difficultyLevel: que?.difficulty_level,
        questionType: que?.question_type,
        explanation: que?.explanation?.description,
        correctAns: que?.correct_option,
        subtopic: que?.sub_topic_reference?._id,
        topic: que?.sub_topic_reference?.topic_reference?._id,
        subdomain:
          que?.sub_topic_reference?.topic_reference?.sub_domain_reference?._id,
        domain:
          que?.sub_topic_reference?.topic_reference?.sub_domain_reference
            ?.domain_reference?._id,
      });
      if (que?.options && Array.isArray(que?.options)) {
        const options = que?.options.map((option, index) => ({
          option: String.fromCharCode(65 + index),
          content: option,
        }));
        setAnswerOptions(options);
      }

      if (que?.question_type === "Multiple Choice Question") {
        setSelectedAnswers(que?.correct_options);
      }
      if(que?.question_type === "Sub Questions"){
        const options = que?.sub_questions?.map((que, index) => ({
          option: String.fromCharCode(65 + index),
          question: que?.question,
          correctAns: que?.correct_option
        }));
        console.log(options)
        setSubQuestions(options);
      }

      setReferences(que?.explanation?.references);
      setPreQueImages(que?.images);
      setPreExImages(que?.explanation?.images);
    } catch (error) {
      getError(error);
    }
  };

  useEffect(() => {
    fetchDomains({ getDomains, setDomains });
  }, []);

  useEffect(() => {
    if (id ? form?.domain : domain) {
      fetchSubdomains({
        getSubdomains,
        setSubdomains,
        id: id ? form?.domain : domain,
      });
    }
  }, [form?.domain, domain, id]);

  useEffect(() => {
    if (id ? form?.subdomain : subdomain) {
      fetchTopics({
        getTopics,
        setTopics,
        id: id ? form?.subdomain : subdomain,
      });
    }
  }, [form?.subdomain, subdomain, id]);

  useEffect(() => {
    if (id ? form?.topic : topic) {
      fetchSubtopics({
        getSubtopics,
        setSubtopics,
        id: id ? form?.topic : topic,
      });
    }
  }, [form?.topic, topic, id]);

  useEffect(() => {
    if (!id) {
      if (!domain || !domains?.some((d) => d?._id === domain)) {
        if (domains?.length > 0) {
          dispatch(setDomain(domains[0]?._id));
        }
      }
    } else {
      return;
    }
  }, [domains, id]);

  useEffect(() => {
    if (!id) {
      if (!subdomain || !subdomains?.some((sd) => sd?._id === subdomain)) {
        if (subdomains?.length > 0) {
          dispatch(setSubdomain(subdomains[0]?._id));
        }
      }
    } else {
      return;
    }
  }, [subdomains, id]);

  useEffect(() => {
    if (!id) {
      if (!topic || !topics?.some((t) => t?._id === topic)) {
        if (topics?.length > 0) {
          dispatch(setTopic(topics[0]?._id));
        }
      }
    } else {
      return;
    }
  }, [topics, id]);

  useEffect(() => {
    if (!id) {
      if (!subtopic || !subtopics?.some((st) => st?._id === subtopic)) {
        if (subtopics?.length > 0) {
          dispatch(setSubtopic(subtopics[0]?._id));
        }
      }
    } else {
      return;
    }
  }, [subtopics, id]);

  const curPageHandler = (p) => setCurPage(p);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const handleShowAddModal = () => setShowAddModal(true);
  const handleHideAddModal = () => setShowAddModal(false);
  const handleShowCancelModal = () => setShowCancelModal(true);
  const handleHideCancelModal = () => setShowCancelModal(false);

  const [showCreatedModal, setShowCreatedModal] = useState(false);
  const handleShowCreatedModal = () => setShowCreatedModal(true);
  const handleHideCreatedModal = () => {
    setShowCreatedModal(false);
    navigate(-1);
  };
  const handleCancelConfirm = () => {
    handleHideCancelModal();
    navigate(-1);
  };

  const handleConfirmBtn = async ({ status = "Completed" }) => {
    let cleanedSelectedAnswers;
    
    if (form?.questionType === "Multiple Choice Question") {
      cleanedSelectedAnswers = selectedAnswers?.filter(
        (answer) =>
          answer.trim() !== "" &&
          answerOptions.some((option) => option.content === answer)
      );
    }

    if (form?.questionType === "Sub Questions") {
      const isValidSubQuestions = subQuestions.every(
        (sub) => sub.question.trim() !== "" && sub.correctAns !== null
      );

      if (!isValidSubQuestions) {
        return toast.warn("Please ensure all sub-questions have a question and a correct answer.");
      }
    }

    if (form?.question) {
      if (status === "Completed" ? (form?.explanation ? true : false) : true) {
        if (
          status === "Completed"
            ? form?.questionType === "Select Best Option" ||
              form?.questionType === "Multiple Choice Question"
              ? answerOptions.length > 1
              : true
            : true
        ) {
          if (
            status === "Completed"
              ? form?.questionType === "Multiple Choice Question"
                ? cleanedSelectedAnswers?.every((selected) =>
                    answerOptions?.some(
                      (optionObj) => optionObj.content === selected
                    )
                  )
                : true
              : true
          ) {
            if (
              status === "Completed"
                ? form?.questionType === "Select Best Option"
                  ? form?.correctAns !== null &&
                    answerOptions.some(
                      (optionObj) => optionObj?.content === form?.correctAns
                    )
                  : form?.correctAns !== null
                : true
            ) {
              try {
                console.log(form);

                const formData = new FormData();

                formData.append(
                  "sub_topic_reference",
                  id ? form?.subtopic : subtopic
                );
                formData.append("question", form?.question);
                formData.append("difficulty_level", form?.difficultyLevel);
                formData.append("explanation[description]", form?.explanation);

                if (id) {
                  references.forEach((reference) => {
                    formData.append(`explanation_reference[]`, reference);
                  });

                  preExImages.forEach((image) => {
                    formData.append(`explanation_images[]`, image);
                  });
                } else {
                  references.forEach((reference) => {
                    formData.append(`explanation[references]`, reference);
                  });
                }

                queImages.forEach((image) => {
                  formData.append(`image`, image);
                });

                exImages.forEach((image) => {
                  formData.append(`image`, image);
                });

                preQueImages.forEach((image) => {
                  formData.append(`images[]`, image);
                });

                formData.append("status", status);

                if (
                  form?.questionType === "Select Best Option" ||
                  form?.questionType === "Multiple Choice Question"
                ) {
                  answerOptions
                    .filter((option) => option?.content.trim() !== "")
                    .forEach((option) => {
                      formData.append(`options`, option.content);
                    });
                }

                formData.append("question_type", form?.questionType);
                formData.append("images_count", queImages?.length);

                if (form?.questionType === "Multiple Choice Question") {
                  cleanedSelectedAnswers?.forEach((answer) => {
                    formData.append("correct_options[]", answer);
                  });
                } else if (form?.questionType === "Sub Questions") {
                  // Append Sub Questions
                  subQuestions.forEach((sub, index) => {
                    formData.append(`sub_questions[${index}][question]`, sub.question);
                    formData.append(`sub_questions[${index}][correct_option]`, sub.correctAns);
                  });
                } else {
                  formData.append("correct_option", form?.correctAns);
                }

                const data = id
                  ? await updateQuestionById({ id, data: formData }).unwrap()
                  : await createQuestion(formData).unwrap();

                console.log(data);
                handleHideAddModal();
                handleShowCreatedModal();

                if (status === "Pending") {
                  toast.success(data?.message);
                  navigate(-1);
                }
              } catch (error) {
                getError(error);
              }
            } else {
              toast.warn("Please Select Correct Answer Option");
            }
          } else {
            toast.warn("Please select valid answer options");
          }
        } else {
          toast.warn("Please add at least 2 answer options");
        }
      } else {
        toast.warn("Please add an explanation");
      }
    } else {
      toast.warn("Please add a question");
    }
  };

  // const domains = [{ name: "Engineering" }, { name: "Medical" }];
  // const subDomains = [
  //   { name: "Mechanical Engineering" },
  //   { name: "Civil Engineering" },
  // ];

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (form.correctAns !== null) {
      handleShowAddModal();
    } else {
      toast.warn("Please Select Correct Answer Option");
    }
  };

  const [answerOptions, setAnswerOptions] = useState([
    { option: "A", content: "" },
    { option: "B", content: "" },
    { option: "C", content: "" },
    { option: "D", content: "" },
  ]);

  const handleAddNewOption = () => {
    const nextOption = String.fromCharCode(answerOptions.length + 65);
    setAnswerOptions([...answerOptions, { option: nextOption, content: "" }]);
  };

  const handleDeleteOption = (index) => {
    const updatedOptions = [...answerOptions];
    const deletedContent = updatedOptions[index].content;
    updatedOptions.splice(index, 1);

    updatedOptions.forEach((option, i) => {
      option.option = String.fromCharCode(i + 65);
    });
    setAnswerOptions(updatedOptions);

    if (form?.questionType === "Multiple Choice Question") {
      setSelectedAnswers(
        selectedAnswers.filter((ans) => ans !== deletedContent)
      );
    }
  };

  const handleContentChange = (index, value) => {
    const updatedOptions = [...answerOptions];
    updatedOptions[index].content = value;
    setAnswerOptions(updatedOptions);
  };

  const handleCheckboxChange = (content) => {
    if (selectedAnswers.includes(content)) {
      setSelectedAnswers(selectedAnswers.filter((ans) => ans !== content));
    } else {
      setSelectedAnswers([...selectedAnswers, content]);
    }
  };

  console.log("selected", selectedAnswers);

  const answerDiv = (option, index) => {
    return (
      <Row key={index}>
        <Col xs={1} className=" m-0">
          <Form.Check
            type={`${
              form?.questionType === "Multiple Choice Question"
                ? "checkbox"
                : "radio"
            }`}
            name="correctAns"
            id={`option-${index}`}
            checked={
              form?.questionType === "Multiple Choice Question"
                ? selectedAnswers.includes(option?.content)
                : form?.correctAns === option?.content
            }
            onChange={() => {
              if (form?.questionType === "Multiple Choice Question") {
                handleCheckboxChange(option?.content);
              } else {
                setForm({ ...form, correctAns: option?.content });
              }
            }}
            // checked={form?.correctAns == option?.content}
            // value={form?.correctAns === option?.content}
            // onChange={()=>setForm({ ...form, correctAns: option?.content })}
          />
        </Col>
        <Col className="p-0 m-0">
          <Row>
            <Col>
              <p
                style={{ color: "rgba(0, 0, 139, 1)" }}
                className="fw-bold"
              >{`${option?.option}]`}</p>
            </Col>
            <Col className="text-end">
              <FiTrash
                color="rgba(249, 84, 40, 1)"
                style={{ cursor: "pointer" }}
                onClick={() => handleDeleteOption(index)}
              />
              {/* <BsThreeDotsVertical color="rgba(45, 44, 44, 1)" /> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <textarea
                className=" rounded"
                style={{
                  width: "100%",
                  minHeight: "100px",
                  maxHeight: "100px",
                  padding: "10px",
                }}
                value={option?.content}
                onChange={(e) => handleContentChange(index, e.target.value)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  console.log(form);

  const [subQuestions, setSubQuestions] = useState([
    { option: "A", question: "",correctAns:null },
    { option: "B", question: "",correctAns:null },
    { option: "C", question: "",correctAns:null },
    { option: "D", question: "",correctAns:null },
  ]);

  // Add a new sub-question
  const handleAddSubQuestion = () => {
    setSubQuestions([...subQuestions, { question: "", correctAns: null }]);
  };

  // Update sub-question text
  const handleSubQuestionChange = (index, value) => {
    const updatedSubQuestions = [...subQuestions];
    updatedSubQuestions[index].question = value;
    setSubQuestions(updatedSubQuestions);
    // setForm({ ...form, subQuestions: updatedSubQuestions });
  };

  // Update sub-question answer (True/False)
  const handleSubQuestionAnswerChange = (index, value) => {
    const updatedSubQuestions = [...subQuestions];
    updatedSubQuestions[index].correctAns = value;
    setSubQuestions(updatedSubQuestions);
    // setForm({ ...form, subQuestions: updatedSubQuestions });
  };

  return (
    <MotionDiv>
      <Row>
        <Col>
          <FormField
            label={" Domain:"}
            type={"select"}
            name={"domain"}
            value={id ? form?.domain : domain}
            onChange={(e) => {
              if (id) {
                handleChange(e);
              } else {
                dispatch(unsetOptions());
                dispatch(setDomain(e.target.value));
              }
            }}
            options={domains?.map((item) => ({
              label: item?.domain_name,
              value: item?._id,
            }))}
          />
        </Col>
        <Col>
          <FormField
            label={"Speciality:"}
            type={"select"}
            name={"subdomain"}
            value={id ? form?.subdomain : subdomain}
            onChange={(e) => {
              if (id) {
                handleChange(e);
              } else {
                dispatch(unsetTopic());
                dispatch(unsetSubtopic());
                dispatch(setSubdomain(e.target.value));
              }
            }}
            options={subdomains?.map((item) => ({
              label: item?.sub_domain_name,
              value: item?._id,
            }))}
          />
        </Col>
        <Col>
          <FormField
            label={" Topic:"}
            type={"select"}
            name={"topic"}
            value={id ? form?.topic : topic}
            onChange={(e) => {
              if (id) {
                handleChange(e);
              } else {
                dispatch(unsetSubtopic());
                dispatch(setTopic(e.target.value));
              }
            }}
            options={topics?.map((item) => ({
              label: item?.topic_name,
              value: item?._id,
            }))}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormField
            label={" Subtopic:"}
            type={"select"}
            name={"subtopic"}
            value={id ? form?.subtopic : subtopic}
            onChange={(e) => {
              if (id) {
                handleChange(e);
              } else {
                dispatch(setSubtopic(e.target.value));
              }
            }}
            options={subtopics?.map((item) => ({
              label: item?.sub_topic_name,
              value: item?._id,
            }))}
          />
        </Col>
        <Col md={4}>
          <FormField
            label={"Select Question Type:"}
            type={"select"}
            name={"questionType"}
            value={form?.questionType}
            onChange={handleChange}
            options={[
              { label: "Select Best Answer", value: "Select Best Option" },
              // { label: "Multiple Choice Question", value: "Multiple Choice Question" },
              { label: "Sub Questions", value: "Sub Questions" },
              { label: "True Or False", value: "True/False" },
            ]}
          />
        </Col>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={7}>
            <Card
              className="border-0 shadow custom-card"
              style={{ height: "100%" }}
            >
              <Card.Header
                style={{ color: "rgba(97, 114, 243, 1)" }}
                className="bg-white"
              >
                <h6 className="blue">
                  <QuestionIcon /> Question:
                </h6>
              </Card.Header>
              <Card.Body>
                <div className="d-flex">
                  <div
                    className="mt-2 blue fw-bold"
                    style={{ fontSize: "0.9rem" }}
                  >
                    Select Difficulty Level:
                  </div>
                  <div
                    className=" p-0 "
                    style={{
                      transform: "scale(0.7)",
                      width: "120px",
                      marginTop: "-0.3rem",
                      marginLeft: "-0.5rem",
                      zIndex: 99,
                    }}
                  >
                    <FormField
                      type={"select"}
                      name={"difficultyLevel"}
                      value={form?.difficultyLevel}
                      onChange={handleChange}
                      options={[
                        { value: "Basic", label: "Basic" },
                        { value: "Medium", label: "Medium" },
                        { value: "Advanced", label: "Advanced" },
                      ]}
                    />
                  </div>
                </div>
                <div>
                  <TextEditor
                    description={form?.question}
                    name={"question"}
                    form={form}
                    setForm={setForm}
                  />
                </div>

                <UploadImages
                  setImages={setQueImages}
                  images={queImages}
                  preImages={preQueImages}
                  setPreImages={setPreQueImages}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col>
          <Card className="border-0 shadow custom-card" style={{ height: "100%" }}>
      <Card.Header className="bg-white">
        <Row>
          <Col style={{ color: "rgba(97, 114, 243, 1)" }} md={form?.questionType === "Sub Questions"?6:4}>
            <h6 className="blue">
             {form?.questionType === "Sub Questions"?
             <><QuestionIcon /> Sub Questions:</>
             :
             <><AnswerIcon /> Answer:</>
            }
            </h6>
          </Col>

          {form?.questionType !== "True/False" && form?.questionType !== "Sub Questions" && (
            <Col className="text-end">
              <Button
                onClick={handleAddNewOption}
                className="blue-btn"
                style={{ fontSize: "0.65rem" }}
              >
                New Option
              </Button>
            </Col>
          )}
        </Row>
      </Card.Header>

      {form?.questionType === "True/False" ? (
        <Card.Body>
          <p>Select Correct Answer</p>
          <Form.Check
            type="radio"
            name="correctAns"
            id="true"
            label="True"
            checked={form?.correctAns === "true"}
            onChange={() => setForm({ ...form, correctAns: "true" })}
          />
          <Form.Check
            type="radio"
            name="correctAns"
            id="false"
            label="False"
            checked={form?.correctAns === "false"}
            onChange={() => setForm({ ...form, correctAns: "false" })}
          />
        </Card.Body>
      ) : form?.questionType === "Multiple Choice Question" || form?.questionType === "Select Best Option" ? (
        <Card.Body style={{ overflowY: "scroll", overflowX: "hidden" }} className="custom-scroll">
          {answerOptions.map((option, index) => answerDiv(option, index))}
        </Card.Body>
      ) : form?.questionType === "Sub Questions" ? (
        <Card.Body style={{ overflowY: "scroll", overflowX: "hidden" }} className="custom-scroll">
          {subQuestions?.map((sub, index) => (
                        <div key={index} className="mb-3">
                          <Row>

<Col>
              <p
                style={{ color: "rgba(0, 0, 139, 1)" }}
                className="fw-bold"
                >{`${sub?.option}]`}</p>
            </Col>
                </Row>
          
              <Form.Group>
              <textarea
                className=" rounded"
                style={{
                  width: "100%",
                  minHeight: "30px",
                  maxHeight: "100px",
                  padding: "10px",
                }}
                  placeholder={`Enter sub-question ${index + 1}`}
                  value={sub.question}
                  onChange={(e) => handleSubQuestionChange(index, e.target.value)}
                />
              </Form.Group>
              <div className="mt-2 d-flex gap-4">
                <Form.Check
                  type="radio"
                  name={`subQuestion-${index}`}
                  id={`true-${index}`}
                  label="True"
                  checked={sub.correctAns === "true"}
                  onChange={() => handleSubQuestionAnswerChange(index, "true")}
                />
                <Form.Check
                  type="radio"
                  name={`subQuestion-${index}`}
                  id={`false-${index}`}
                  label="False"
                  checked={sub.correctAns === "false"}
                  onChange={() => handleSubQuestionAnswerChange(index, "false")}
                />
              </div>
             
              
            </div>
          ))}
          {/* <Button className="blue-btn mt-2" style={{ fontSize: "0.65rem" }} onClick={handleAddSubQuestion}>
            Add Sub Question
          </Button> */}
        </Card.Body>
      ) : null}
    </Card>
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <Card className="border-0 shadow">
              <Card.Body>
                <Row>
                  <Col className="blue">
                    <h6>Explanation:</h6>
                  </Col>

                  <Col></Col>
                </Row>
                <Row>
                  <Col>
                    <TextEditor
                      description={form?.explanation}
                      form={form}
                      setForm={setForm}
                      name={"explanation"}
                    />

                    {/* <textarea
                      required
                      style={{
                        width: "100%",
                        minHeight: "100px",
                        maxHeight: "100px",
                        padding: "10px",
                      }}
                      name="explanation"
                      value={form?.explanation}
                      onChange={handleChange}
                    /> */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <References
                      setReferences={setReferences}
                      references={references}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <UploadImages
                      setImages={setExImages}
                      images={exImages}
                      preImages={preExImages}
                      setPreImages={setPreExImages}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <Button
              variant="transparent"
              className="del-btn"
              onClick={handleShowCancelModal}
            >
              Cancel
              {/* <FaRegRectangleXmark /> */}
            </Button>
          </Col>
          <Col className="text-end">
            <Button
              variant="transparent"
              className="add-btn m-1"
              onClick={() => handleConfirmBtn({ status: "Pending" })}
            >
              Save as Draft
              {/* <FaRegSave /> */}
            </Button>
            <Button
              variant="transparent"
              type="submit"
              className="blue-btn m-1"
            >
              Save Question
            </Button>
          </Col>
        </Row>
      </Form>

      <ModalTemplate
        title={"Are you sure you want to eliminate this question?"}
        src={"/icons/question-red.png"}
        onDiscard={handleHideCancelModal}
        show={showCancelModal}
        onHide={handleHideCancelModal}
        onConfirm={handleCancelConfirm}
      />
      <ModalTemplate
        title={"Are you sure you want to save this question?"}
        src={"/icons/question-green.png"}
        loading={createLoading || updateloading}
        onDiscard={handleHideAddModal}
        show={showAddModal}
        onHide={handleHideAddModal}
        onConfirm={handleConfirmBtn}
      />
      <ModalTemplate
        title={`You have successfully ${
          id ? "updated the question" : "created a new question"
        }`}
        src={"/icons/tick.png"}
        show={showCreatedModal}
        onHide={handleHideCreatedModal}
      />
    </MotionDiv>
  );
}

export default AddEditQuestion;
