import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { FiEdit } from 'react-icons/fi'
import { FaCircleXmark } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'
import CreateMarkup from '../layout/CreateMarkup';
import { trimText } from '../../utils/trimText';

function QuestionModal({show,onHide,question,idx}) {

    const navigate = useNavigate();

    const isMultipleChoice = question?.question_type === 'Multiple Choice Question';
    const isCorrectOption = (option)=>{
   return isMultipleChoice
      ? question?.correct_options?.includes(option)
      : option === question?.correct_option;

    }
  
  return (
    <Modal show={show} onHide={onHide} size='xl' centered>
    <Modal.Body className='p-4'>
      <div className='w-100 pb-3'>
      <span
                    onClick={onHide}
                    style={{
                        position: 'absolute',
                        top: '2px',
                        right: '2px',
                        cursor: 'pointer'
                    }}
                >
                    {/* <CancelModalIcon /> */}
                    <FaCircleXmark color="rgba(249, 84, 40, 1)" size={25} />
                </span>
      </div>
      <Row>
        <Col>
        <h5 className='blue'>Question No. {idx+1}</h5>
        
        </Col>
        <Col className='text-end'>
        <FiEdit color='rgba(71, 84, 103, 1)' style={{cursor:'pointer'}}  onClick={()=>navigate(`/admin/questions/edit-question/${question?._id}`)}/>
        </Col>
      </Row>
      <div className='p-2 border rounded' style={{wordBreak:'break-word',maxHeight:'200px',overflowY:'auto',overflowWrap:'break-word'}}><CreateMarkup content={question?.question}/></div>

      <Row className=' my-3'>
        <Col><p> Question Type: <span className=' blue-outline-box rounded-2 p-1 px-3 text-nowrap'>{question?.question_type ==='Select Best Option'?'Select Best Answer':question?.question_type}</span></p></Col>
        <Col className='text-end'><p>Difficulty Level: <span className=' blue-outline-box rounded-2 px-3 p-1 text-nowrap'>{question?.difficulty_level}</span></p></Col>
      </Row>
  
      <Row>
        <Col>
        <div className="border rounded py-2" style={{maxHeight:'250px',overflowY:'auto'}}>
         {question?.question_type === 'Sub Questions'?
          question?.sub_questions?.map((que, index) => (
            <div>
            <p key={index} style={{wordBreak:'break-word',overflowWrap:'break-word'}} className="p-1">
              <span className='my-2 ms-2' style={{ color: 'var(--primary-color)', fontWeight: 600 }}>
                {`${String.fromCharCode(65 + index)} ] `}
              </span>
             <span 
            
  
              >{que?.question}</span>
            </p>
            <p className='text-capitalize' >Correct option: <span style={{color:'var(--primary-color)'}}>{String(que?.correct_option)}</span></p>
            </div>
          ))
         :
      question?.options?.map((option, index) => (
        <p key={index} style={{wordBreak:'break-word',overflowWrap:'break-word'}} className="p-1">
          <span className='my-2 ms-2' style={{ color: 'var(--primary-color)', fontWeight: 600 }}>
            {`${String.fromCharCode(65 + index)} ] `}
          </span>
         <span 
         className={`${ isCorrectOption(option)?
          'fw-bold':''
        }`}

            style={{color:`${isCorrectOption(option)? 'var(--primary-color)':''}`}}>{trimText(option)}</span>
        </p>
      ))}

    </div>
        </Col>
      </Row>
    </Modal.Body>
  </Modal>
  )
}

export default QuestionModal